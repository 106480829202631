.ken {
  animation: kenburns 15s infinite;
}
@keyframes kenburns {
  0% {
    transform: scale3d(1.2, 1.6, 1.6);
    animation-timing-function: ease-out;
  }

  50% {
    transform: scale3d(1, 1, 1);
    animation-timing-function: ease-in;
  }

  100% {
    transform: scale3d(1.2, 1.6, 1.6);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  /* 80% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  85% {
    opacity: 0.9;
  }

  90% {
    opacity: 0.1;
  }

  100% {
    -webkit-transform: scale(20);
    -ms-transform: scale(20);
    transform: scale(20);
    opacity: 0;
  } */
}

.animClass li {
  opacity: 0;
}

.fadeInLeft {
  animation: fadeInLeft ease-out 0.5s forwards;
}

.animClass li:nth-child(1) {
  animation-delay: 0s;
}
.animClass li:nth-child(2) {
  animation-delay: 0.1s;
}
.animClass li:nth-child(3) {
  animation-delay: 0.2s;
}
.animClass li:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-30%);
  }
  80%{
    transform: translateX(0%);
  }
  100% {
    opacity: 1;
  }
}

.breathing {
  animation: breathing 2s infinite;
}

textarea {
  vertical-align: top !important;
  resize: none;
}
